<!--
 * @Author: your name
 * @Date: 2021-12-15 16:47:42
 * @LastEditTime: 2021-12-23 14:18:01
 * @LastEditors: Please set LastEditors
 * @Description: 墒情仪
 * @FilePath: \new-website-1123\src\views\special\sq101\index.vue
-->
<template>
  <div class="sq101-page">
    <page1></page1>
    <page2 class="opacity-page"></page2>
    <div class="sticky-box1">
      <div class="sticky-child">
        <page3></page3>
      </div>
    </div>
    <page4 class="opacity-page scale-page"></page4>
    <page5 class="opacity-page scale-page"></page5>
  </div>
</template>

<script>
import page1 from "./page1.vue";
import page2 from "./page2.vue";
import page3 from "./page3.vue";
import page4 from "./page4.vue";
import page5 from "./page5.vue";
import {getNesViewApi} from '@/api/index.js'

export default {
  name: "sq101-page",
  components: {
    page1,
    page2,
    page3,
    page4,
    page5,
  },
  data() {
    return {
      scrollTop: 0,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.cScroll, false);
    this.addView()

  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.cScroll, false);
  },
  methods: {
    addView(){
      getNesViewApi({type:1,pid:"39e0c778fb35490a9a828b37b14be796"})
    },
    /**
     * @description: 监听滚动事件
     */
    cScroll() {
      let html = document.querySelector("html");
      this.scrollTop = html.scrollTop;
      if (window.innerWidth > 800) {
        this.page1s();
        this.page2s();
      }
    },
    page2s() {
      let box = document.querySelector(".sticky-box1");
      let div = box.querySelector(".sq101-page3");
      let filterBox = div.querySelector(".filter-div");
      let dataImg = div.querySelector(".data-img");
      let back1 = div.querySelector(".back1");
      let back2 = div.querySelector(".back2");
      let warn = div.querySelector(".w-div");
      let text1 = div.querySelector(".text1");
      let text2 = div.querySelector(".text2");
      let text3 = div.querySelector(".text3");
      let text4 = div.querySelector(".text4");

      let ctop = this.scrollTop - box.offsetTop;
      if (ctop > 4500) {
        let rate = Math.min(1, (ctop - 4500) / 500);
        text4.style.opacity = filterBox.style.opacity = rate;

        back2.style.opacity = 1;
        dataImg.style.opacity = warn.style.opacity = text3.style.opacity = 0;
      } else if (ctop > 3700) {
        text4.style.opacity = 0;

        let rate = Math.min(1, (ctop - 3700) / 500);
        back2.style.opacity = rate;

        filterBox.style.opacity =
          dataImg.style.opacity =
          warn.style.opacity =
          text3.style.opacity =
            1 - rate;
      } else if (ctop > 3000) {
        back2.style.opacity = 0;
        filterBox.style.opacity = dataImg.style.opacity = 1;

        let rate = Math.min(1, (ctop - 3000) / 500);
        warn.style.opacity = text3.style.opacity = rate;

        text2.style.opacity = 0;
      } else if (ctop > 2500) {
        warn.style.opacity = text3.style.opacity = 0;

        let rate = Math.min(1, (ctop - 2500) / 500);
        text2.style.opacity = 1 - rate;
      } else if (ctop > 1800) {
        let rate = Math.min(1, (ctop - 1800) / 500);
        text2.style.opacity = rate;

        dataImg.style.height = "70%";
        back1.style.opacity = 0;
      } else if (ctop > 1400) {
        text2.style.opacity = 0;

        let rate = Math.min(1, (ctop - 1400) / 400);
        dataImg.style.height = 70 + (1 - rate) * 220 + "%";
        back1.style.opacity = 1 - rate;

        text1.style.opacity = 0;
      } else if (ctop > 1000) {
        back1.style.opacity = 1;

        dataImg.style.height = "300%";

        let rate = Math.min(1, (ctop - 1000) / 400);

        filterBox.style.opacity = 1;
        text1.style.opacity = 1 - rate;
      } else if (ctop > 400) {
        let rate = Math.min(1, (ctop - 400) / 400);
        filterBox.style.opacity = 1;
        dataImg.style.opacity = text1.style.opacity = rate;
      } else if (ctop > 200) {
        dataImg.style.opacity = text1.style.opacity = 0;

        let rate = (ctop - 200) / 300;
        filterBox.style.opacity = rate;
      } else if (ctop < 200) {
        filterBox.style.opacity = 0;
      }
    },
    page1s() {
      let div = document.querySelector(".sq101-page1");
      let lSpan = div.querySelectorAll(".l-span");
      let wh = window.innerHeight;
      for (let i = 0; i < lSpan.length; i++) {
        const element = lSpan[i];
        let ctop = this.scrollTop - element.offsetTop + wh / 2;
        let rate = Math.min(1, ctop / 300);
        if (ctop > 0) {
          element.style.opacity = rate;
        } else {
          element.style.opacity = 0;
        }
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.sticky-child {
  position: sticky;
  top: 0;
  height: 100vh;
}
.sticky-box1 {
  height: calc(100vh + 5100px);
}
@media screen and (max-width: $mobile-width) {
  .sticky-child {
    position: relative;
    height: auto;
  }
  .sticky-box1 {
    height: auto;
  }
}
</style>
