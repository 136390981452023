<!--
 * @Author: your name
 * @Date: 2021-12-15 17:30:36
 * @LastEditTime: 2021-12-23 14:10:00
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\views\special\sq101\page2.vue
-->
<template>
  <div class="sq101-page2">
    <span class="t-span">土壤专家的“听诊器”</span>
    <span class="p-24"
      >采用介电常数原理监测土壤水分含量以及温度状态进行动态观测，并可利用4G/5G技术，将所测数据上报到远程联物网平台。</span
    >
    <span class="p-24">整体可以检测3层~5层或定制层数土壤温湿度状态；</span>
    <span class="p-24"
      >可以快速、全面的了解土壤墒情信息，科学地制定灌溉计划与抗旱救灾方案。</span
    >
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.sq101-page2 {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 120px 0 80px 0;
  .t-span {
    font-size: 60px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: $mobile-width) {
  .sq101-page2 {
    .t-span {
      font-size: 0.55rem;
      margin-bottom: 20px;
    }
    .p-24 {
      padding: 0 12px;
    }
  }
}
</style>
