<!--
 * @Author: your name
 * @Date: 2021-12-15 18:33:11
 * @LastEditTime: 2022-01-26 18:09:38
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\views\special\sq101\page4.vue
-->
<template>
  <div class="sq101-page4">
    <div class="title-div">
      <span class="t-span">APP、小程序、WEB三端查看</span>
      <span class="p-24">数据可通过多种模式提取查看。</span>
    </div>
    <ul class="msg-ul">
      <li>手机APP</li>
      <li>云平台运营</li>
      <li>手机小程序</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.sq101-page4 {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 180px;
  background-image: url("https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/sq101/5.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  .title-div {
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    .t-span {
      font-size: 60px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
      margin-bottom: 20px;
    }
  }
  .msg-ul {
    margin-top: 9%;
    font-size: 60px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: $color-active;
    line-height: 80px;
  }
}
@media screen and (max-width: $mobile-width) {
  .sq101-page4 {
    padding-top: 1.64rem;
    .title-div {
      .t-span {
        font-size: 0.55rem;
        margin-bottom: 0.18rem;
      }
    }
    .msg-ul {
      margin-top: 80%;
      font-size: 0.55rem;
      line-height: 0.73rem;
    }
  }
}
</style>
