<!--
 * @Author: your name
 * @Date: 2021-12-15 17:00:39
 * @LastEditTime: 2021-12-23 14:06:30
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\views\special\sq101\page1.vue
-->
<template>
  <div class="sq101-page1">
    <d-title model="ST-XJ101" ch="太阳能墒情仪"></d-title>

    <div class="img-div">
      <img
        src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/sq101/1.png"
        alt="上太科技"
      />

      <div class="msg-div">
        <span class="l-span l-left l-1">监测层数可定制</span>
        <span class="l-span l-right l-2">数据储存量大</span>
        <span class="l-span l-left l-3">GPS地图定位</span>
        <span class="l-span l-right l-4">
          三端查看
          <span class="s-span">(APP、小程序、WEB)</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import dTitle from "@/components/sTitle/title";
export default {
  name: "",
  components: {
    dTitle,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.sq101-page1 {
  padding-top: 100px;
  .img-div {
    display: flex;
    justify-content: center;
    position: relative;
    margin-top: 50px;
    span {
      display: block;
      position: absolute;
      font-size: 60px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: $color-active;
      line-height: 48px;
      opacity: 0;
      .s-span {
        display: block;
        font-size: 24px;
        white-space: nowrap;
        opacity: 1;
      }
      &.l-left {
        left: 50%;
        transform: translateX(-35vw);
      }
      &.l-right {
        text-align: right;
        right: 50%;
        transform: translateX(35vw);
      }
      &.l-1 {
        top: 30%;
      }
      &.l-2 {
        top: 45%;
      }
      &.l-3 {
        top: 60%;
      }
      &.l-4 {
        top: 75%;
      }
    }
  }
}
@media screen and (max-width: $mobile-width) {
  .sq101-page1 {
    padding-top: 60px;
    .img-div {
      .msg-div {
        position: absolute;
        top: 32%;
        height: 50%;
        width: calc(100% - 48px);
        left: 24px;
        background-color: rgba($color: #000000, $alpha: 0.2);
        display: flex;
        flex-direction: column;
        align-content: center;

        span {
          position: absolute;
          font-size: 0.55rem;
          line-height: 0.44rem;
          opacity: 1;
          white-space: nowrap;
          .s-span {
            display: block;
            font-size: 0.22rem;
            white-space: nowrap;
            opacity: 1;
          }
          // &.l-left {
          left: 50%;
          transform: translateX(-50%);
          // }
          &.l-right {
            text-align: center;
            right: unset;
            transform: translateX(-50%);
          }
          &.l-1 {
            top: 20%;
          }
          &.l-2 {
            top: 40%;
          }
          &.l-3 {
            top: 60%;
          }
          &.l-4 {
            top: 80%;
          }
        }
      }
    }
  }
}
</style>
