<!--
 * @Author: your name
 * @Date: 2021-12-15 18:39:19
 * @LastEditTime: 2021-12-27 10:17:15
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\views\special\sq101\page5.vue
-->
<template>
  <div class="sq101-page5">
    <div class="title-div">
      <span class="t-span">
        <span>GPS地图定位</span>
        <span class="f-span">，</span>
        <span>自动采集坐标信息</span>
      </span>
      <span class="p-24"
        >通过GPS可知道设备及数据采集点具体的地理位置，防盗防位移。</span
      >
    </div>
    <div class="img-div">
      <img src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/sq101/6.jpg" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.sq101-page5 {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 100px;
  background-image: url("https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/sq101/6.jpg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  .title-div {
    display: flex;
    flex-direction: column;
    .t-span {
      font-size: 60px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
      margin-bottom: 20px;
    }
  }
  .img-div {
    display: none;
  }
}

@media screen and (max-width: $mobile-width) {
  .sq101-page5 {
    padding-top: 60px;
    height: auto;
    background-image: unset;
    overflow: hidden;
    .title-div {
      display: flex;
      flex-direction: column;
      .t-span {
        font-size: 0.55rem;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        .f-span {
          display: none;
        }
      }
    }

    .img-div {
      display: flex;
      justify-content: center;
      img {
        width: 140%;
        object-fit: cover;
      }
    }
  }
}
</style>
