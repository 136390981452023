<!--
 * @Author: your name
 * @Date: 2021-12-15 17:39:37
 * @LastEditTime: 2021-12-23 14:52:18
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\views\special\sq101\page3.vue
-->
<template>
  <div class="sq101-page3">
    <div class="back-div back0 abs-div">
      <div class="back-c"></div>
    </div>
    <div class="back-div back1 abs-div"></div>

    <div class="filter-div abs-div"></div>

    <div class="img-div abs-div">
      <img
        src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/sq101/2.png"
        alt="上太科技"
        class="data-img"
      />

      <div class="w-div"><span></span><span></span><span></span></div>

      <div class="text-div abs-div text1">
        <span class="t-span">各类土质，全面检测</span>
        <span class="p-24"
          >适用于农业大棚、花卉养殖、生态环境、气象站监测等各类环境</span
        >
      </div>

      <div class="text-div abs-div text2">
        <span class="t-span">监测层数可定制</span>
        <span class="c-span"
          >可检测3层~5层或更多层（需定制）土壤温湿度状态</span
        >
        <span class="c-span">快速、全面的了解土壤墒情信息</span>
      </div>

      <div class="text-div abs-div text3">
        <span class="t-span">超限预警</span>
        <span class="c-span">土壤墒情实时监测系统采集的数据</span>
        <span class="c-span">可设置高低超限值，</span>
        <span class="c-span">可自动进行数据预警分析</span>
      </div>

      <div class="text-div abs-div text4">
        <span class="t-span">实时监测</span>
        <span class="c-span">实时监测不同层次土壤温湿度，</span>
        <span class="c-span">实现对各个土层土壤中水分含量的动态观测，</span>
        <span class="c-span">土壤盐分积累可视化</span>
      </div>
    </div>
    <div class="back-div back2 abs-div">
      <div class="text-div abs-div text4">
        <span class="t-span">实时监测</span>
        <span class="c-span">实时监测不同层次土壤温湿度，</span>
        <span class="c-span">实现对各个土层土壤中水分含量的动态观测，</span>
        <span class="c-span">土壤盐分积累可视化</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.sq101-page3 {
  height: 100vh;
  position: relative;
  .abs-div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .back-div {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 1;
    &.back0 {
      .back-c {
        position: absolute;
        height: 50%;
        width: 100%;
        color: rgba($color: $color-active, $alpha: 0.2);
        background-image: linear-gradient(
          to bottom,
          rgba($color: $color-active, $alpha: 0.5),
          rgba($color: $color-active, $alpha: 0)
        );
        bottom: 0;
      }
    }
    &.back1 {
      background-image: url("https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/sq101/3.jpg");
    }
    &.back2 {
      opacity: 0;
      background-image: url("https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/sq101/4.jpg");
      .text-div {
        opacity: 0;
      }
    }
  }
  .filter-div {
    z-index: 2;
    background-color: rgba($color: #000, $alpha: 0.8);
    opacity: 0;
  }
  .img-div {
    z-index: 4;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow: hidden;

    @keyframes yA {
      from {
        transform: scale(0.2);
        opacity: 1;
      }
      80% {
        opacity: 1;
      }
      to {
        transform: scale(2);
        opacity: 0;
      }
    }
    .w-div {
      position: absolute;
      top: 27%;
      left: 50%;
      width: 0;
      height: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      span {
        top: 0;
        position: absolute;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        border: 1px solid #ee584b;
        transform: scale(0.2);
        box-shadow: 0 0 8px #ee584bff inset;
        &:first-child {
          animation: yA 2s 0 infinite linear;
        }
        &:nth-child(2) {
          animation: yA 2s 1s infinite linear;
        }
        &:nth-child(3) {
          animation: yA 2s 2s infinite linear;
        }
      }
    }
    .data-img {
      height: 300%;
      opacity: 0;
      position: absolute;
      top: 25%;
    }
    .text-div {
      display: flex;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
      .t-span {
        margin-bottom: 30px;
      }
      &.text1 {
        opacity: 0;
      }
      &.text2 {
        opacity: 0;
        justify-content: flex-start;
        padding-top: 100px;
        .t-span {
          margin-bottom: 11%;
        }
      }
      &.text3 {
        opacity: 0;
        justify-content: flex-start;
        padding-top: 100px;
        .t-span {
          margin-bottom: 11%;
        }
      }
      &.text4 {
        opacity: 0;
        justify-content: flex-start;
        padding-top: 100px;
        .t-span {
          margin-bottom: 11%;
        }
      }
    }
  }
}
.t-span {
  font-size: 85px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
}
.c-span {
  font-size: 60px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: $color-active;
  line-height: 76px;
}

@media screen and (max-width: $mobile-width) {
  .sq101-page3 {
    height: unset;
    position: relative;
    display: flex;
    flex-direction: column;
    .back-div {
      &.back2 {
        height: 100vh;
        position: relative;
        opacity: 1;
        .text-div {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          height: unset;
          opacity: 1;
          padding: 0 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          display: flex;
          flex-direction: column;
          background-color: rgba($color: #000, $alpha: 0.7);
          .t-span {
            font-size: 0.55rem;
            margin-bottom: 0.27rem;
          }
          .c-span {
            font-size: 0.55rem;
            text-align: center;
            padding: 0 12px;
            line-height: 0.85rem;
          }
          &.text4 {
            opacity: 1;
          }
        }
      }
    }
    .img-div {
      position: relative;
      opacity: 1;
      padding: 0.45rem 0;
      .data-img {
        position: relative;
        opacity: 1;
        width: 35%;
        height: unset;
      }
      .text-div {
        height: unset;
        .t-span {
          font-size: 0.55rem;
          margin-bottom: 0.27rem;
        }
        .c-span {
          font-size: 0.55rem;
          text-align: center;
          padding: 0 12px;
          line-height: 0.85rem;
        }
        &.text1 {
          top: 10%;
          opacity: 1;
        }
        &.text2 {
          top: 20%;
          opacity: 1;
          .t-span {
            margin-bottom: 40%;
          }
        }
        &.text3 {
          top: 50%;
          opacity: 1;
          .t-span {
            margin-bottom: 60%;
          }
        }
        &.text4 {
          opacity: 0;
        }
      }
    }
  }
}
</style>
